import React from 'react'

function ListFeedbackByUser() {
return (
    <div>
<h1>FB by ID</h1>
    </div>
)
}

export default ListFeedbackByUser
import CircularProgress from "@material-ui/core/CircularProgress";
import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Link,
  Redirect,
  Route,
  Switch,
  useLocation
} from "react-router-dom";
import "./App.css";
import Authenticate from "./components/Authenticate";
import Dashboard from "./components/Dashboard";
import PrivateRoute from "./components/PrivateRoute";
import { UserContext } from "./context/userContext";
import Issues from "./routes/issues.js";
import TestList from "./routes/testlist.js";
import { checkUser } from "./services/magic";
import GetUserData from "./routes/getUserData";
import Crabrave from "./crabrave.mp3";
import OngoingList from "./routes/ongoing-list.js"
import UpdateIssue from "./routes/updateIssue"
import NewFeedback from "./routes/newFeedback";
import ListFeedbackByUser from "./routes/listFeedbacksByUser";
import FeedbacksList from "./routes/feedbacks-list.js";
import ListTopTwenty from "./routes/topCustomers/customerList";
import axios from "axios";
import Alert from "./components/alert"



var marker = 0;
function changeColor() {
  var r = document.querySelector(":root");
  if (marker === 1) {
    changeBack();
  } else {
    r.style.setProperty("--elements", "0");
    r.style.setProperty("--elementsRev", "255");
    r.style.setProperty("--background", "#fdfdfd");
    r.style.setProperty("--text", "#3a3a3a");
    r.style.setProperty("--navBarColour", "#f1f1f1");
    r.style.setProperty("--option", "#000000");
    r.style.setProperty("--shadow", "17px 17px 42px -9px rgba(0,0,0,0.26)");
    r.style.setProperty("--selectColor", "#000000");
    r.style.setProperty("--opacity", "0.05");
    r.style.setProperty("--opacity2", "1");
    r.style.setProperty("--buttonOn", "#2d2d2d29");
    r.style.setProperty("--switchColor", "#ffffff");
    marker++;

    document.getElementById("darkModeSw").style.bottom = "-6rem";
    setTimeout(function () {
      document.getElementById("darkModeSw").style.bottom = "0.5rem";
      document.getElementById("darkModeSw").innerHTML = "🌑";
      document.getElementById("darkModeSw").style.transform = "rotate(45deg)";
    }, 500);
  }
}
function changeBack() {
  var r = document.querySelector(":root");
  r.style.setProperty("--elements", "255");
  r.style.setProperty("--elementsRev", "0");
  r.style.setProperty("--background", "#121212");
  r.style.setProperty("--text", "#ffffff");
  r.style.setProperty("--navBarColour", "#151515");
  r.style.setProperty("--option", "#ffffff");
  r.style.setProperty("--shadow", "none");
  r.style.setProperty("--selectColor", "#666666");
  r.style.setProperty("--opacity", "0.1");
  r.style.setProperty("--opacity2", "0.2");
  r.style.setProperty("--buttonOn", "#c0c0c096");
  r.style.setProperty("--switchColor", "#353535");
  marker = 0;

  document.getElementById("darkModeSw").style.bottom = "-6rem";

  setTimeout(function () {
    document.getElementById("darkModeSw").style.bottom = "0.5rem";
    document.getElementById("darkModeSw").innerHTML = "☀️";
    document.getElementById("darkModeSw").style.transform = "rotate(0deg)";
  }, 500);
}
var i = 0;
var audio = new Audio(Crabrave);
function raveMode() {
  i++;
  console.log("Click me 5 times to activate rave mode!");
  if (i === 5) {
    audio.play();
    raveModeB();
    setTimeout(function () {
      i = 7;
    }, 17100);
  } else if (i > 5) {
    setTimeout(function () {
      document.getElementById("navBar").style.backgroundColor =
        "var(--navBarColour)";
    }, 200);
    setTimeout(function () {
      document.body.style.backgroundColor = "var(--background)";
    }, 200);
    setTimeout(function () {
      document.getElementById("darkModeSw").style.bottom = "1rem";
      document.getElementById("darkModeSw").style.transform = "rotate(0deg)";
    }, 50);
    audio.pause();
    audio.currentTime = 0;
    console.log(i);
    setTimeout(function () {
      i = 0;
    }, 200);
  }
}
function raveModeB() {
  if (i === 5) {
    setTimeout(function () {
      document.body.style.backgroundColor = "red";
      document.getElementById("navBar").style.backgroundColor = "red";
    }, 50);
    setTimeout(function () {
      document.body.style.backgroundColor = "blue";
      document.getElementById("navBar").style.backgroundColor = "blue";
    }, 100);
    setTimeout(function () {
      document.body.style.backgroundColor = "green";
      document.getElementById("navBar").style.backgroundColor = "green";
    }, 200);
    setTimeout(function () {
      document.body.style.backgroundColor = "purple";
      document.getElementById("navBar").style.backgroundColor = "purple";
    }, 300);
    setTimeout(function () {
      document.getElementById("darkModeSw").style.bottom = "8rem";
      document.getElementById("darkModeSw").style.transform = "rotate(90deg)";
    }, 50);
    setTimeout(function () {
      document.getElementById("darkModeSw").style.bottom = "2rem";
      document.getElementById("darkModeSw").style.transform = "rotate(180deg)";
    }, 100);
    setTimeout(function () {
      document.getElementById("darkModeSw").style.bottom = "8rem";
      document.getElementById("darkModeSw").style.transform = "rotate(270deg)";
    }, 200);
    setTimeout(function () {
      document.getElementById("darkModeSw").style.bottom = "2rem";
      document.getElementById("darkModeSw").style.transform = "rotate(360deg)";
    }, 300);
    setTimeout(function () {
      raveModeA();
    }, 650);
  } else if (i > 5) {
    setTimeout(function () {
      document.getElementById("navBar").style.backgroundColor =
        "var(--navBarColour)";
    }, 200);
    setTimeout(function () {
      document.body.style.backgroundColor = "var(--background)";
    }, 200);
    setTimeout(function () {
      document.getElementById("darkModeSw").style.bottom = "1rem";
      document.getElementById("darkModeSw").style.transform = "rotate(0deg)";
    }, 50);
  }
}
function raveModeA() {
  if (i === 5) {
    setTimeout(function () {
      document.body.style.backgroundColor = "purple";
      document.getElementById("navBar").style.backgroundColor = "purple";
    }, 0);
    setTimeout(function () {
      document.body.style.backgroundColor = "green";
      document.getElementById("navBar").style.backgroundColor = "green";
    }, 100);
    setTimeout(function () {
      document.body.style.backgroundColor = "blue";
      document.getElementById("navBar").style.backgroundColor = "blue";
    }, 200);
    setTimeout(function () {
      document.body.style.backgroundColor = "red";
      document.getElementById("navBar").style.backgroundColor = "red";
    }, 300);
    setTimeout(function () {
      document.getElementById("darkModeSw").style.bottom = "2rem";
      document.getElementById("darkModeSw").style.transform = "rotate(360deg)";
    }, 300);
    setTimeout(function () {
      document.getElementById("darkModeSw").style.bottom = "8rem";
      document.getElementById("darkModeSw").style.transform = "rotate(270deg)";
    }, 200);
    setTimeout(function () {
      document.getElementById("darkModeSw").style.bottom = "2rem";
      document.getElementById("darkModeSw").style.transform = "rotate(180deg)";
    }, 100);
    setTimeout(function () {
      document.getElementById("darkModeSw").style.bottom = "8rem";
      document.getElementById("darkModeSw").style.transform = "rotate(90deg)";
    }, 50);
    setTimeout(function () {
      raveModeB();
    }, 650);
  } else if (i > 5) {
    setTimeout(function () {
      document.getElementById("navBar").style.backgroundColor =
        "var(--navBarColour)";
    }, 200);
    setTimeout(function () {
      document.body.style.backgroundColor = "var(--background)";
    }, 200);
    setTimeout(function () {
      document.getElementById("darkModeSw").style.bottom = "1rem";
      document.getElementById("darkModeSw").style.transform = "rotate(0deg)";
    }, 50);
  }
}

const FourOhFour  = () => {
const {pathname} = useLocation()
window.location({pathname})

}

function App() {
  const [user, setUser] = useState({ isLoggedIn: null, email: "" });
  const [loading, setLoading] = useState();
  const [isPressed, setIsPressed] = useState(false);

  const handleOpen = () => {
    document.getElementById("issuesModal").style.height = "50rem";
  };
  const handleClose = () => {
    document.getElementById("issuesModal").style.height = "0rem";
    setTimeout(() => {
      setIsPressed(false);
    },1500)
  };




document.addEventListener("keydown", function (key) {
  if(key.key === "F2"){
  handleOpen();

  
 }
})

document.addEventListener("keydown", function (key) {
  if(key.key === "Escape"){
  handleClose();}

})

  

  useEffect(() => {
    const validateUser = async () => {
      setLoading(true);
      try {
        await checkUser(setUser);
        setLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    validateUser();
  }, [user.isLoggedIn]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "50vh",
        }}
      >
        <CircularProgress color="primary" size="10rem" />
      </div>
    );
  }

  return (
    <UserContext.Provider value={user}>
      <Router>
        {user.isLoggedIn && <Redirect to={{ pathname: "/list" }} />}
        {user.isLoggedIn ? (
          <>
            {" "}
            
            <div className="NavBar" id="navBar">
              <nav>
                <div className="NavHeader" onClick={raveMode}></div>
                <div className="NavButton" id="addnew">
                  <div onClick={handleOpen} className="NavButton">
                    Add new issue
                  </div>
                </div>
                <div className="NavButton" id="list">
                  <Link to="/list">
                    <div onClick={handleClose} className="NavButton">
                      List issues
                    </div>
                  </Link>
                </div>
                <div className="NavButton" id="ongoing">
                  <Link to="/ongoing">
                    <div onClick={handleClose} className="NavButton">
                      Ongoing Issues
                    </div>
                  </Link>
                </div>
                <div className="NavButton" id="ongoing">
                  <Link to="/update-issue">
                    <div onClick={handleClose} className="NavButton">
                      Update Issue
                    </div>
                  </Link>
                </div>
                <div className="darkMode" type="button" onClick={changeColor}>
                  <div className="darkModeSwitchContainer">
                    <p className="darkModeSwitch" id="darkModeSw">
                      ☀️
                    </p>
                  </div>
                </div>
                <div className="NavButton" id="getuserdata">
                  <Link to="/getuser">
                    <div className="NavButton">Get user data</div>
                  </Link>
                </div>

                <div className="divider" style={{textAlign:"center"}}>User Feedbacks <span>⬇️</span></div>
                <div className="NavButton" id="newFeedback">
                  <Link to="/feedback/new">
                    <div className="NavButton">Add new feedback</div>
                  </Link>
                </div>
                <div className="NavButton" id="listFeedback">
                  <Link to="/feedback/list">
                    <div className="NavButton">List feedbacks</div>
                  </Link>
                </div>
                {/* <div className="NavButton" id="feedbackByUser">
                  <Link to="/feedback/list-by-id">
                    <div className="NavButton">List feedbacks by User</div>
                  </Link>
                </div> */}
                <div className="divider" style={{backgroundColor:"#E84545", textAlign:"center"}}>Top 20 <span>⬇️</span></div>
                <div className="NavButton" id="top20">
                  <Link to="/top20">
                    <div className="NavButton">Risk Status</div>
                  </Link>
                </div>
              </nav>
              <Dashboard />
            </div>
            <Alert/>
            
            <div style={{height:"0rem"}} className="issuesModal" id="issuesModal">
              <Issues close={handleClose} pressed={setIsPressed} />
            </div>
            
            
          </>
        ) : (
          <></>
        )}

        <Switch>
          <Route exact path="/" component={Authenticate} />
          
          <PrivateRoute path="/dashboard" component={Dashboard} />
          <PrivateRoute path="/list" component={TestList} />
          
          {/* <PrivateRoute path="/issues" component={Issues} /> */}
          <Route exact path="/getuser"><PrivateRoute path="/getuser" component={GetUserData} /></Route>
          <PrivateRoute path="/ongoing" component={OngoingList} />
          <PrivateRoute path="/update-issue" component={UpdateIssue} />
          <PrivateRoute path="/feedback/list-by-id" component={ListFeedbackByUser} />
          <PrivateRoute path="/feedback/list" component={FeedbacksList}/>
          <PrivateRoute path="/feedback/new" component={NewFeedback}/>
          <Route exact path="/top20"><PrivateRoute component={ListTopTwenty} path="/top20"/></Route>
          
          <PrivateRoute path="*"><FourOhFour/></PrivateRoute>
        </Switch>
      </Router>
    </UserContext.Provider>

  );

  
}

export default App;

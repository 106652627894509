import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { loginUser } from '../services/magic';

const Authenticate = () => {
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState('');
  const [error, setError] = useState(null);
  const history = useHistory();
  const handleSubmit = async (event) => {
    const bnemail = email.indexOf("@bitninja.io")
    event.preventDefault();
    if(bnemail > -1){    setLoading(true);
      if (!email) {
        setLoading(false);
        setError('Email is Invalid');
        return;
      }
      try {
        await loginUser(email);
        setLoading(false);
        history.replace('/dashboard');
      } catch (error) {
        setError('Unable to log in');
        console.error(error);
      }} else (alert("It is a no go for you!"))

  };
  const handleChange = (event) => {
    setEmail(event.target.value);
  };



  return (
    <div className="InputDiv" >
      <div className="InputContainerMiddle">
      <h1 className="Heading">BitNinja Issue Tracker</h1>
      <form onSubmit={handleSubmit} >
          <input className="Input"
            type="email"
            name="email"
            id="email"
            value={email}
            onChange={handleChange}
            placeholder="Email Address"
          />
          <p className="text-danger text-small">{error}</p>

        <button
        className="Submit"
          type="submit"
          size="md"
          variant="primary"
        >
          {loading ? 'Loading...' : 'Lessgooo'}
        </button>
      </form>
      </div>
    </div>
  );
};
export default Authenticate;
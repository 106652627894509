// Table.js
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { React, useState } from "react";
import { useFilters, usePagination, useTable } from "react-table";



export default function FeedbacksTable({ columns, data, reload, show }) {

  const [natureFilter, setNatureFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("")
  const [uidFilter, setUidFilter] = useState("");

  const handleFilterChangeDate = e => {
    const value = e.target.value || undefined;
    setFilter("date_created",value);
    setDateFilter(value)
  }

  const handleFilterChangeNature = e => {
    const value = e.target.value || undefined;
    setFilter("feedback_nature", value);
    setNatureFilter(value);

  };

  const handleFilterChangeUid = e => {
    const value = e.target.value || undefined;
    setFilter("user_id", value);
    setUidFilter(value);
  };


  const resetFilters = (e) => {
    const value = ""

    setFilter("feedback_nature", value);
    setFilter("user_id", value);
    setFilter("date_created",value)
    setNatureFilter(value);
    setUidFilter(value);
    setDateFilter(value);
  }






    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setFilter,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state
      } = useTable({
        columns,
        data
      },
      useFilters, usePagination);

      const {pageIndex} =  state


      return (

          <div className="InputContainer">
              <div className="InputContainerMiddleTable" style={show ? {marginBottom: "0px", display:"block"} : {marginBottom: "0px", display:"none"}}>
<div className="InputDivTable">
  <div className="Half1">
  <input className="SearchBox"
        value={uidFilter}
        onChange={handleFilterChangeUid}
        placeholder={"UID"}/>

<input className="SearchBox Pointer" value={dateFilter} onChange={handleFilterChangeDate} type="date" placeholder={"Date"}/>

<select className="SearchBox Pointer" name="module"  value={natureFilter}
  onChange={handleFilterChangeNature}
  placeholder={"Feedback Nature"}>
                        <option selected disabled value="">Feedback Nature</option>
                        <option value="positive">Positive</option>
                        
                        <option value="negative">Negative</option>
                        <option value="mixed">Mixed</option>
                        
                       
                    </select>

                   
                  
                    </div>

                    
                    <div className="Half2">
                    <button title="Reload data and reset filters" className="ReloadButton" onClick={() => {reload();resetFilters()}}>Reset filters</button>
                    </div>


                    </div>



        <MaUTable {...getTableProps()}>
          <TableHead>

            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell className="tableHeader" {...column.getHeaderProps()}>
                      {column.render("Header")}
                      </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow className="tableRow" {...row.getRowProps()}>
                  {row.cells.map(cell => {if(cell.value === "true"){return <TableCell className="tableCell" {...cell.getCellProps()}>
                        {"Yes!✨"}
                        </TableCell>;}
                        else if (cell.value === "false"){return <TableCell className="tableCell" {...cell.getCellProps()}>
                        {"Nop! 📉"}
                        </TableCell>;}
                        else {return <TableCell className="tableCell" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                        </TableCell>;}

                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>

        <div className="Chart">
       {/*  <PieChart
  data={[
    { title: 'One', value: 10, color: '#E38627' },
    { title: 'Two', value: 15, color: '#C13C37' },
    { title: 'Three', value: 20, color: '#6A2135' },
  ]}
/>; */}
</div>
<div style={{margin: "10px"}}>
  <span className="PageNo">
    Page{' '}
    <strong>
      {pageIndex + 1} of {pageOptions.length}
    </strong>{' '}
  </span>
          <button className="PageButton" disabled={!canPreviousPage} style={{marginRight: "10px"}} onClick={() => previousPage()}>Previous</button>
          <button className="PageButton" disabled={!canNextPage} onClick={() => nextPage()}>Next</button>
        </div>
        </div>
        </div>
      );
    }

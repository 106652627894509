// Table.js
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { React, useState } from "react";
import { useFilters, usePagination, useTable } from "react-table";



export default function PaginatedTable({ columns, data, reload }) {

    
    const [moduleFilter, setModuleFilter] = useState("");
  const [dateFilter, setDateFilter] = useState("")
    const [uidFilter, setUidFilter] = useState("");
    const [agentFilter, setAgentFilter] = useState("");
    const [solveFilter, setSolveFilter] = useState("");

    // Update the state when input changes

    const handleFilterChangeDate = e => {
      const value = e.target.value || undefined;
      setFilter("dateCreated",value);
      setDateFilter(value)
    }

    const handleFilterChangeModule = e => {
      const value = e.target.value || undefined;
      setFilter("issueType", value);
      setModuleFilter(value);
    };



    const handleFilterChangeUid = e => {
      const value = e.target.value || undefined;
      setFilter("userId", value);
      setUidFilter(value);
    };

    const handleFilterAgent = e => {
      const value = e.target.value || undefined;
      setFilter("added_by", value);
      setAgentFilter(value);
    };
    const handleFilterSolve = e => {
      const value = e.target.value || undefined;
      setFilter("solved", value);
      setSolveFilter(value);
    };

    const resetFilters = (e) => {
      const value = ""

      setFilter("issueType", value);
      setFilter("userId", value);
      setFilter("added_by", value);
      setFilter("solved", value);
      setFilter("dateCreated",value)
      setModuleFilter(value);
      setUidFilter(value);
      setAgentFilter(value)
      setSolveFilter(value);
      setDateFilter(value);
    }



    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setFilter,
        nextPage,
        previousPage,
        canNextPage,
        canPreviousPage,
        pageOptions,
        state
      } = useTable({
        columns,
        data
      },
      useFilters, usePagination);

      const {pageIndex} =  state


      return (

          <div className="InputContainer">
              <div className="InputContainerMiddleTable">
<div className="InputDivTable">
  <div className="Half1-2">
  <input className="SearchBox"
        value={uidFilter}
        onChange={handleFilterChangeUid}
        placeholder={"UID"}/>

<input className="SearchBox" value={dateFilter} onChange={handleFilterChangeDate} type="date" placeholder={"Date"}/>




                    <select className="SearchBox Pointer" name="module"  value={moduleFilter}
  onChange={handleFilterChangeModule}
  placeholder={"Module"}>
                        <option value="">Module</option>
                        <option value="Other">Other</option>
                        <option value="License">License</option>
                        <option value="Billing-Payment">Billing/Payment</option>
                        <option value="Install Help">Install Help</option>
                        <option value="General Question">General Question</option>
                        <option value="Config Help">Config Help</option>
                        <option value="IP Filter">IP Filter</option>
                        <option value="WAF">WAF</option>
                        <option value="Port Honeypot">PortHoneypot</option>
                        <option value="Anti Flood">Anti Flood</option>
                        <option value="DoS Detection">DoS Detection</option>
                        <option value="Malware Detection">Malware Detection</option>
                        <option value="Malware Scanner">Malware Scanner</option>
                        <option value="Sense Log">Sense Log</option>
                        <option value="SSL terminating">SSL Terminating</option>
                        <option value="Defense Robot">Defense Robot</option>
                        <option value="Proxy Filter">Proxy Filter</option>
                        <option value="Price Increase">Price Increase</option>

                    </select>
                    <select className="SearchBox Pointer" name="module"  value={agentFilter}
  onChange={handleFilterAgent}
  placeholder={"Added By"}>
                        <option selected disabled value="">Added by</option>
                        <option value="jacint.lazok">Jácint</option>
                        
                        <option value="balazs.konczili">Bazsi</option>
                        <option value="akos.szoke">Sógi</option>
                        <option value="laszlo.takacs">Taki</option>
                       
                    </select>
                    <select className="SearchBox Pointer" name="module"  value={solveFilter}
  onChange={handleFilterSolve}
  placeholder={"Added By"}>
                        <option selected value="">Solved</option>
                        <option value="true">Yep!</option>
                        <option value="false">Nope</option>
                    </select>
                    </div>

                    <div className="Half2">
                    <button title="Reload data and reset filters" className="ReloadButton" onClick={() => {reload();resetFilters()}}>Reload data</button>
                    </div>
                    </div>



        <MaUTable {...getTableProps()}>
          <TableHead>

            {headerGroups.map(headerGroup => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell className="tableHeader" {...column.getHeaderProps()}>
                      {column.render("Header")}
                      </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow className="tableRow" {...row.getRowProps()}>
                  {row.cells.map(cell => {if(cell.value === "true"){return <TableCell className="tableCell" {...cell.getCellProps()}>
                        {"Yes!✨"}
                        </TableCell>;}
                        else if (cell.value === "false"){return <TableCell className="tableCell" {...cell.getCellProps()}>
                        {"Nop! 📉"}
                        </TableCell>;}
                        else {return <TableCell className="tableCell" {...cell.getCellProps()}>
                        {cell.render("Cell")}
                        </TableCell>;}

                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>

        <div className="Chart">
       {/*  <PieChart
  data={[
    { title: 'One', value: 10, color: '#E38627' },
    { title: 'Two', value: 15, color: '#C13C37' },
    { title: 'Three', value: 20, color: '#6A2135' },
  ]}
/>; */}
</div>
<div style={{margin: "10px"}}>
  <span className="PageNo">
    Page{' '}
    <strong>
      {pageIndex + 1} of {pageOptions.length}
    </strong>{' '}
  </span>
          <button className="PageButton" disabled={!canPreviousPage} style={{marginRight: "10px"}} onClick={() => previousPage()}>Previous</button>
          <button className="PageButton" disabled={!canNextPage} onClick={() => nextPage()}>Next</button>
        </div>
        </div>
        </div>
      );
    }

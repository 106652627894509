import axios from "axios";
import React, { Component } from "react";
import swal from "sweetalert2";
import { UserContext } from "../context/userContext";
import "../index.css";

const key = process.env.REACT_APP_KEY;

class Issues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userEmail: null,
      value: "none",
    };
  }

  divstatus = (e) => {
    if (e.target.value === "General Question" || e.target.value === "Config Help") {
      this.setState({ value: "none" });
    } else {
      this.setState({ value: "" });
    }
  };

  static contextType = UserContext;

  componentDidMount() {
    let { email } = this.context;

    let userEmail = email.split("@");
    this.setState({ userEmail: userEmail[0] });
  }

  handleSubmit = (event) => {
    event.preventDefault();

    let uid = event.target.uid.value;
    let agentName = this.state.userEmail;
    //    let uname = event.target.uname.value;
    let module = event.target.module.value;
    var platform = event.target.platform.value;
    let ticketid = event.target.ticketid.value;
    let solved = event.target.solved.value;
    let escalated = event.target.escalated.checked;
    let agentVer = event.target.agentVer.value;
    let note = event.target.note.value;
    let qori = event.target.qori.value;
    let inTrial = event.target.inTrial.value;
    console.log(inTrial);

    //    let seasoned = event.target.seasoned.value
    var platformlink = "";

    if (platform === "zenDesk") {
      platformlink = "deskLink";
    } else if (platform === "Jira") {
      platformlink = "jiraLink";
    } else if (platform === "Intercom") {
      platformlink = "intercomLink";
    } else if (platform === "Slack") {
      platformlink = "slack";
    } else if (platform === "Skype") {
      platformlink = "skype";
    }

    axios
      .post(process.env.REACT_APP_API_URL + `issue?key=${key}`, {
        uid: uid,
        agentName: agentName,
        module: module,
        platform: platform,
        ticketid: ticketid,
        solved: solved,
        platformlink: platformlink,
        note: note,
        escalated: escalated,
        agentVer: agentVer,
        qori: qori,
        inTrial: inTrial,
      })
      .then((response) => {
        if (response.status === 200) {
          swal.fire("You are wonderful!", "Successfully submitted", "success");
          document.getElementById("inputTracker").reset();
          setTimeout(() => {
            document.getElementById("issuesModal").style.height = "0rem";
          }, 3000);
        } else if (response.status !== 200) {
          swal.fire(
            "Uh-oh!",
            "Something is not right, we have dispatched a team of highly trained doggies. If you meet them, make sure to pet Nefi!",
            "error"
          );
        }
      });
  };
  render() {
    const style = { display: this.state.value };
    return (
      <div className="InputContainer">
        <div className="InputContainerMiddle" id="inputContainer">
          <span onClick={() => this.props.close()}>❌</span>
          <p className="Heading">BitNinja Issue Tracker!</p>
          <form onSubmit={this.handleSubmit} id="inputTracker">
            <div className="InputDiv">
              <input className="Input" type="number" min="0" name="uid" autoComplete="off" placeholder="UID" required />
            </div>
            <div className="InputDiv">
              <input className="Input" type="text" name="agentVer" autoComplete="off" placeholder="Agent Version" />
            </div>
            {/* <div className="InputDiv">
                    <input className="Input" type="text" name="uname" pattern=".{2,}" autoComplete="off" placeholder="User Name" required/>
                    </div> */}
            <div className="InputDiv">
              <select title="What is the type of the issue?" className="InputDrop" name="module" onChange={this.divstatus} required>
                <option value="" disabled selected>
                  Issue Type
                </option>
                <option value="Other">Other</option>
                <option value="License">License</option>
                <option value="Billing-Payment">Billing/Payment</option>
                <option value="Install Help">Install Help</option>
                <option value="General Question">General Question</option>
                <option value="Config Help">Config Help</option>
                <option value="IP Filter">IP Filter</option>
                <option value="WAF">WAF</option>
                <option value="Port Honeypot">PortHoneypot</option>
                <option value="Anti Flood">Anti Flood</option>
                <option value="DoS Detection">DoS Detection</option>
                <option value="Malware Detection">Malware Detection</option>
                <option value="Sense Log">Sense Log</option>
                <option value="Defense Robot">Defense Robot</option>
                <option value="Proxy Filter">Proxy Filter</option>
                <option value="Price Increase">Price Increase</option>
              </select>
            </div>
            <div style={style}>
              <div id="QorI" className="InputDivCheckbox">
                <div className="LabelCenter">
                  <input type="radio" id="question" name="qori" value="question"></input>
                  <label for="question">Question</label>
                </div>
                <div className="LabelCenter">
                  <input type="radio" id="issue" name="qori" value="issue"></input>
                  <label for="question">Issue</label>
                </div>
              </div>
            </div>

            <div className="InputDiv">
              {/* <select title="Seasoned NO if user spending his/her first month at BN with PRO at least one sub, if user is with us for more than 1 month then YES" className="InputDrop" name="seasoned" required>
                    <option value="" disabled selected>Seasoned Customer?</option>
                        <option value="1">Yes</option>
                        <option value="0">No</option>

                    </select> */}
              <select title="Does the customer have an active trial period? " className="InputDrop" name="inTrial" required>
                <option value="" disabled selected>
                  Is the customer in trial?
                </option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
            <div className="InputDiv">
              <select title="On what platform did the customer reported the issue?" className="InputDrop" name="platform" required>
                <option value="" disabled selected>
                  Platform
                </option>
                <option value="zenDesk">ZenDesk</option>
                <option value="Jira">Jira</option>
                <option value="Intercom">Intercom</option>
                <option value="Slack">Slack</option>
                <option value="Skype">Skype</option>
              </select>
            </div>
            <div className="InputDiv">
              <input className="Input" type="text" name="ticketid" autoComplete="off" placeholder="Ticket ID" required />
            </div>
            <div className="InputDiv">
              <input className="InputNote" type="text" name="note" autoComplete="off" placeholder="Notes" />
            </div>
            <div className="InputDivCheckbox">
              <div className="LabelCenter">
                <select title="Solved by?" className="InputDrop" name="solved">
                  <option value="false" selected>
                    Solved by?
                  </option>
                  <option value="Dev">Dev</option>
                  <option value="Supp">Support</option>
                </select>
              </div>

              <div className="LabelCenter">
                <label for="escalated">Escalated?</label>
                <input className="InputCheckbox" type="checkbox" name="escalated" />
              </div>
            </div>
            <div className="InputDiv">
              <button type="submit" className="Submit">
                {" "}
                Send baby!
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default Issues;

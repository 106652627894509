import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import swal from "sweetalert2";
import OngoingTable from "./ongoing-table";

import Legend from "../components/legend"




function OngoingList() {

  const apiurl = process.env.REACT_APP_API_URL;

  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  
  const key = process.env.REACT_APP_KEY;

  

  useEffect(() => {
    (async () => {
      console.log(apiurl);
    const result = await axios(apiurl + `ongoing_issues?key=${key}`);
      setData(result.data);
    })();
    setReload(false);
  }, [reload]);

  //Reload data in the table//

  const ReloadData = () => {
    setReload(true);
  };

  //Solve case//
  const Solve = (uuid) => {
    console.log(uuid)
    swal
      .fire({
        title: "You want to solve this case, are you sure?",
        icon: "warning",
        
        showDenyButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "Cancel",
        
      })
      .then((result) => {
        if (result.isConfirmed) {
          swal
          .fire({
            title: "Who solved the issue eventually?",
            icon: "warning",
            showCancelButton: true,
            showDenyButton: true,
            confirmButtonText: "Developers",
            denyButtonText: "Support",
            cancelButtonText: "Cancel"

            
            
          }).then((result) => {
            if (result.isConfirmed) {
              
              axios
                .post(apiurl + `solve_ongoing?key=${key}`, {
                  issue_id:uuid,
                  result: "Dev",
                })
                .then((res) => {  
                  console.log(res)                
                  if (res.status === 200) {
                    swal.fire({
                      title: "Yay! Entry updated.",
                    });
                    setReload(true)
                    
                  }
                });
          
                
            } 
            else if (result.isDenied) {
              
              axios
                .post(apiurl + `solve_ongoing?key=${key}`, {
                  
                  result: "Supp",
                  issue_id:uuid
                })
                .then((res) => {                  
                  if (res.status === 200) {
                    swal.fire({
                      title: "Yay! Entry updated.",
                    });
                    setReload(true)
                    
                  }
                });

            } 
            
            
            else if (result.dismiss === swal.DismissReason.cancel) {
              swal.fire("AIGHT THEN");
            }
          })
        } 
        else if (result.isDenied) {
                   
          
          
        } 
        
        
 
      });
  };
  ////////////////////////////

  //Deleting entries//
  const Delete = (uuid) => {
    swal
      .fire({
        title: "You are about to delete the entry, are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Captain!",
        cancelButtonText: "No, what am I doing?!",
      })
      .then((result) => {
        if (result.value) {
          axios
            .delete(apiurl + `issue?key=${key}`, {
              params: {
                uuid: uuid,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                swal.fire({
                  title: "Great, data deleted!",
                });
                setTimeout(() => {
                  setReload(true);
                }, 1000);
              }
            });
        } else if (result.dismiss === swal.DismissReason.cancel) {
          swal.fire("Request cancelled");
          setReload(true);
        }
      });
  };
  ////////////////////////////

  const columns = useMemo(
    () => [
      {
        Header: " ",

        columns: [
          {
            Header: "UID",
            accessor: "userId",
            Cell: (userId) => (
              <a
                href={
                  "https://office.bitninja.io/index.php/user/view?id=" +
                  userId.value
                }
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                {userId.value}{" "}
              </a>
            ),
          },
          {
            Header: "Name",
            Cell: props => {
              
              const copyId = (uuid) => {
                navigator.clipboard.writeText(uuid)
                swal.fire({
                  showConfirmButton:false,
                  title:"Issue ID copied",
                  timer:1000
                  
                })
                  
            
              }
              return(
                <div className="tooltip" onClick={() => copyId(props.row.original.issue_id)}>{props.row.original.user_name}
                <p className="tooltiptext">Copy issue ID</p>
                </div>
              )
            }
          },
          {
            Header: "Ongoing for",
            accessor: "date_created",
            Cell: (v) => {
              moment.locale("hu");
              const addedDate = Date.parse(v.value);
              const parsedDate = moment(addedDate).format('YYYY-MM-DD')
              const nowDate = new Date()
              const today = nowDate.getFullYear()+'-'+(nowDate.getMonth()+1)+'-'+nowDate.getDate()
              var start = moment(today, "YYYY-MM-DD");
              var end = moment(parsedDate, "YYYY-MM-DD");
              
              //Difference in number of days
              
             

                let dayDiff = moment.duration(start.diff(end)).asDays()
                
            //Difference in number of days
            
              return <p>{dayDiff} day(s) - {parsedDate}</p>;
            },
          },
          {
            Header: "Platform",
            accessor: "platform",
          },

          {
            Header: "Link",
            accessor: "link",
            Cell: (link) => (
              <div className="GoToLink">
                <a href={link.value} target="_blank" rel="noreferrer">
                  Go to issue.
                </a>
              </div>
            ),
          },

          // {
          //   Header: "Note ",
          //   accessor: "note",
          // },

          {
            Header: "Added by ",
            accessor: "added_by",
          },
          
          {
            Header: "Severity",
            Cell: (props) => {
              let level = props.row.original.severity
              let issue_id = props.row.original.issue_id
              let text = ''
              let [editable,setEditable] = useState(false)
              let [severityLevel,setSeverityLevel] = useState('')

              const handleChange = (e) => {
                let value = e.target.value
                setSeverityLevel(value)
              }
              
              const editNote = () => {
                setEditable(true)
              }

              const saveNote = () => {
              

                if (!severityLevel || level === severityLevel) {
                  swal.fire({
                    showConfirmButton:false,
                    text:"You changed nothing",
                    timer:1000
                  })
                } else (
                  axios.patch(apiurl + `ongoing_issues/?key=${key}`, {
                    issue_id: issue_id,
                    severity:severityLevel
                }).then(result => {
                  console.log(result)
                  if(result.status === 200){
                    swal.fire({
                      title:`Severity changed to Level ${severityLevel}`,
                      icon:"success",
                      showConfirmButton:false,
                      timer:1500
                    })
                  } 
                  setReload(true)
                }).catch(err => swal.fire({
                  icon:"error",
                  title:"Something went wrong, please try again!"
                }))
                )
                setEditable(false)
         
              }
              if(level > 0) {
                
                text = "Level "+level
              } else (text = "No severity")
              return(
                <>
                
                  {editable ? (
                    <>
                    <select onChange={e => handleChange(e)}>
                    <option default>
                  Select level
                  </option>
                  <option value="1">
                  Level 1 
                  </option>
                  <option value="2">
                  Level 2
                  </option>
                  <option value="3">
                  Level 3
                  </option>
                  <option value="4">
                  Level 4
                  </option>

                  
                  </select>
                    <button
                    className="Edit"
                      onClick={() => {
                        saveNote();
                      }}
                    >
                      <span>💾</span>
                    </button>
                    </>
                  ) : ( 
                    <>
                    
                    { level === 1 ? <p style={{color:"#FF4545"}}>{text}</p> : null}
                    { level === 2 ? <p style={{color:"#FB6944"}}>{text}</p> : null}
                    { level === 3 ? <p style={{color:"#FF875A"}}>{text}</p> : null}
                    { level === 4 ? <p style={{color:"#FFAA71"}}>{text}</p> : null}
                    { !level ? <p>{text}</p> : null}
                    <button className="Edit" onClick={() => editNote()}>
                      <span>✏️</span>
                    </button>
                    </>
                  )}
                </>
              )
            }
          },
          {
            Header: "Escalated?",
            accessor: "escalated",
          },
          {
            Header: "Solved",
            accessor: "solved",
          },
         
          {
            Header: "Solve",
            
            Cell: (props) =>  {
              let uuid = props.row.original.issue_id
              
              return (
                <div>
                <div className="SolveDelete">
                  <button
                    className="SolveDeleteButton"
                    onClick={() => Solve(uuid)}
                  >
                    <span style={{ cursor: "pointer" }}>🚀</span>
                  </button>
                </div>

              </div>
              )

            }
              

            ,
          },
        ],
      },
    ],
    []
  );
  return (
    <div className="TestList"> 
    <Legend

    title="Severity Levels"
    firstLevel="Severity Level 1 - Highest Priority"
    secondLevel = "Severity Level 2 - High Priority"
    thirdLevel="Severity Level 3 - Medium Priority"
    fourthLevel="Severity Level 4 - Low Priority"
    firstLevelDesc="System is down, everyone (user and end-user) is affected."
    secondLevelDesc="Critical issue in service, high volume of end-users (customers) complaints."
    thirdLevelDesc="Moderate issue in service, few user complaints."
    fourthLevelDesc="Minor issue in service, need help in config or how-to."
    
    
    />
      <OngoingTable columns={columns} data={data} reload={ReloadData} />
     

      {/* <CountIssues reload={reload}/> */}
    </div>
  );
}

export default OngoingList;

import React from 'react';

export default function Legend(props) {
    return(
     <div className="InputContainerMiddleTable">
         <h1>{props.title}</h1>
  <div className="flex-grid-thirds">
      <div className="col">
          <h3 style={{color:"#FF4545"}}>{props.firstLevel}</h3>
          <p>{props.firstLevelDesc}</p>
      </div>
      <div className="col">
          <h3 style={{color:"#FB6944"}}>{props.secondLevel}</h3>
          <p>{props.secondLevelDesc}</p>
      </div>
      <div className="col">
          <h3 style={{color:"#FF875A"}}>{props.thirdLevel}</h3>
          <p>{props.thirdLevelDesc}</p>
      </div>
      <div className="col">
          <h3 style={{color:"#FFAA71"}}>{props.fourthLevel}</h3>
          <p>{props.fourthLevelDesc}</p>
      </div>

  </div>
     </div>
    )
}
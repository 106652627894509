import axios from "axios";
import React, { Component } from "react";
const key = process.env.REACT_APP_KEY;

class GetUserData extends Component {
  constructor() {
    super();

    this.state = {
      userData: {
        name: "",
        mrr: "",
        activeSub: "",
        activeLic: "",
        email: "",
        csmAgent: "",
      },
      userId: "",
      show: true,
    };
  }

  getData = (e) => {
    e.preventDefault();
    axios
      .get(process.env.REACT_APP_API_URL + `getuser?key=${key}`, {
        params: {
          uid: this.state.userId,
        },
      })
      .then((result) =>
        this.setState({
          userData: {
            name: result.data[0].name,
            mrr: result.data[0].mrr,
            activeSub: result.data[0].activeSub,
            activeLic: result.data[0].activeLic,
            email: result.data[0].email,
            csmAgent: result.data[0].csmAgent,
            salesPerson: result.data[0].salesperson
          },
          show: false,
        })
      );
  };

  onChangeHandler = (e) => {
    this.setState({ userId: e.target.value });
  };

  resetSearch = () => {
    this.setState({ show: true, userId: "" });
  };

  render() {
    return (
      <div className="InputContainerMiddle">
        {this.state.show ? (
          <div className="Padding">
            <h1 className="Heading">Get information of BN user</h1>
            <form onSubmit={(e) => this.getData(e)}>
              <div className="MarginBottom">
              <input
                className="Input  InputModify"
                type="number"
                onChange={(e) => this.onChangeHandler(e)}
                id="userid"
                placeholder="UID"
                autoComplete="off"
                value={this.state.userId}
              ></input>
              <button className="SendButton" type="submit">Send</button>
              </div>
            </form>
          </div>
        ) : (
          <div className="Heading">
            
            <p className="Heading">
              Name: <strong>{this.state.userData.name}</strong>
            </p>
            <p className="Heading">
              Email: <strong>{this.state.userData.email}</strong>
            </p>
            <p className="Heading">
              Current Mrr: <strong>${this.state.userData.mrr}</strong>
            </p>
            <p className="Heading">
              Current Active Subscription:{" "}
              <strong>{this.state.userData.activeSub}</strong>
            </p>
            <p className="Heading">
              Current Active License:{" "}
              <strong>{this.state.userData.activeLic}</strong>
            </p>
            <p className="Heading">
              Customer Success Manager:{" "}
              <strong>{this.state.userData.csmAgent}</strong>
            </p>
            <p className="Heading">
              Sales Person:{" "}
              <strong>{this.state.userData.salesPerson}</strong>
            </p>
            <button className="ReloadButton" onClick={() => this.resetSearch()}>New Search</button>
          </div>
        )}
      </div>
    );
  }
}

export default GetUserData;

import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import swal from "sweetalert2";
import PaginatedTable from "./paginatedTable";

const key = process.env.REACT_APP_KEY;

function TestList() {
  const apiurl = process.env.REACT_APP_API_URL;

  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    (async () => {
      console.log(apiurl);
    const result = await axios(apiurl + `list?key=${key}`);
      setData(result.data);
    })();
    setReload(false);
  }, [reload]);

  //Reload data in the table//

  const ReloadData = () => {
    setReload(true);
  };

  //Solve case//
  const Solve = (uuid) => {
    swal
      .fire({
        title: "You want to solve this case, are you sure?",
        icon: "warning",
        showCancelButton: true,
        showDenyButton: true,
        confirmButtonText: "Developers",
        confirmButtonValue: "Dev",
        denyButtonText: "Support",
        cancelButtonText: "The fuck' I'm doing?",
      })
      .then((result) => {
        if (result.isConfirmed) {
          console.log(uuid);
          axios
            .patch(apiurl + `issue?key=${key}`, {
              uuid: uuid,
              result: "Dev",
            })
            .then((res) => {
              if (res.status === 200) {
                swal.fire({
                  title: "Yay! Entry updated.",
                });
                setReload(true);
              }
            });
        } 
        else if (result.isDenied) {
          console.log(uuid);
          
          axios
            .patch(apiurl + `issue?key=${key}`, {
              uuid: uuid,
              result: "Supp",
            })
            .then((res) => {
              if (res.status === 200) {
                swal.fire({
                  title: "Yay! Entry updated.",
                });
                setReload(true);
              }
            });
        } 
        
        
        else if (result.dismiss === swal.DismissReason.cancel) {
          swal.fire("AIGHT THEN");
        }
      });
  };
  ////////////////////////////

  //Deleting entries//
  const Delete = (uuid) => {
    swal
      .fire({
        title: "You are about to delete the entry, are you sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes Captain!",
        cancelButtonText: "No, what am I doing?!",
      })
      .then((result) => {
        if (result.value) {
          axios
            .delete(apiurl + `issue?key=${key}`, {
              params: {
                uuid: uuid,
              },
            })
            .then((res) => {
              if (res.status === 200) {
                swal.fire({
                  title: "Great, data deleted!",
                });
                setTimeout(() => {
                  setReload(true);
                }, 1000);
              }
            });
        } else if (result.dismiss === swal.DismissReason.cancel) {
          swal.fire("Request cancelled");
          setReload(true);
        }
      });
  };

    ////////////Create Ongoing Issue////////////////

  ////////////////////////////

  const columns = useMemo(
    () => [
      {
        Header: " ",

        columns: [
          {
            Header: "UID",
            accessor: "userId",
            Cell: (userId) => (
              <a
                href={
                  "https://office.bitninja.io/index.php/user/view?id=" +
                  userId.value
                }
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                {userId.value}{" "}
              </a>
            ),
          },
          {
            Header: "Name",
            Cell: props => {
    
              const copyId = (uuid) => {
                navigator.clipboard.writeText(uuid)
                swal.fire({
                  showConfirmButton:false,
                  title:"Issue ID copied",
                  timer:1000
                  
                })
                  
            
              }
              return(
                <div className="tooltip" onClick={() => copyId(props.row.original.uuid)}>{props.row.original.userName}
                <p className="tooltiptext">Copy issue ID</p>
                </div>
              )
            }
          },
          {
            Header: "Date & Time",
            accessor: "dateCreated",
            Cell: (v) => {
              moment.locale("hu");
              const today = Date.parse(v.value);
              const currentDate = moment(today).format("YYYY-MM-DD");

              return <p>{currentDate}</p>;
            },
          },
          {
            Header: "Module",
            accessor: "issueType",
          },
          {
            Header: "Platform",
            accessor: "platform",
          },
          {
            Header: "Link",
            accessor: "link",
            Cell: (link) => (
              <div className="GoToLink">
                <a href={link.value} target="_blank" rel="noreferrer">
                  Go to issue.
                </a>
              </div>
            ),
          },
          {
            Header: "Note",
            accessor: "note",
            Cell: (note) => {
              const [editable, setEditable] = useState(false);
              const [disabled, setDisabled] = useState("disabled");
              const noteRef = useRef(null);
              const [modifiedNote, setModifiedNote] = useState(note.value);
              const uuid = note.row.original.uuid;

              const editNote = () => {
                setEditable(true);
                setDisabled("");
                setTimeout(() => {
                  noteRef.current.focus();
                }, 100);
              };

              const saveNote = () => {
                if(note.value === modifiedNote){
                  swal.fire({
                    title: "You have not changed anything, did ya?!",
                    timer: 2000,
                    icon: "warning",
                    showConfirmButton: false,
                  });
                } else (                setTimeout(() => {
                  axios
                    .patch(apiurl + `note?key=${key}`, {
                      note: modifiedNote,
                      uuid: uuid,
                    })
                    .then((result) => {
                      if ((result = 200)) {
                        swal.fire({
                          title: "Note updated",
                          timer: 1300,
                          icon: "success",
                          showConfirmButton: false,
                        });
                      } else
                        swal.fire({
                          title: "Error, please try again",
                          timer: 1300,
                          icon: "warning",
                          showConfirmButton: false,
                        });
                    });
                }, 1000))


                setEditable(false);
                setDisabled("disabled");


              };

              return (
                <div>
                  <td key={uuid} id={uuid} className="Inline">
                    <input
                      className="Note"
                      disabled={disabled}
                      ref={noteRef}
                      onChange={(e) => setModifiedNote(e.target.value)}
                      value={modifiedNote}
                    ></input>
                  </td>
                  {editable ? (
                    <button
                      className="Edit"
                      onClick={() => {
                        saveNote();
                      }}
                    >
                      <span>💾</span>
                    </button>
                  ) : (
                    <button className="Edit" onClick={() => editNote()}>
                      <span>✏️</span>
                    </button>
                  )}
                </div>
              );
            },
          },
          // {
          //   Header: "Note ",
          //   accessor: "note",
          // },
  
          {
            Header: "Added by ",
            accessor: "added_by",
          },
          {
            Header: "Escalated?",
            accessor: "escalated",
          },
          {
            Header: "Solved",
            accessor: "solved",
          },
          {
            Header: "Solve / Delete",
            accessor: "uuid",
            Cell: (uuid) => (
              <div>
                <div className="SolveDelete">
                  <button
                    className="SolveDeleteButton"
                    onClick={() => Solve(uuid.value)}
                  >
                    <span style={{ cursor: "pointer" }}>🚀</span>
                  </button>
                </div>
                <div className="SolveDelete">
                  <button
                    className="SolveDeleteButton"
                    onClick={() => Delete(uuid.value)}
                  >
                    <span style={{ cursor: "pointer" }}>🔥</span>
                  </button>
                </div>
              </div>
            ),
          },
          {
            Header: "Add to Ongoing list",
            Cell:(props) => {
              
              let base = props.row.original
              
              let name = base.userName
              let userId = base.userId
              let dateCreated = base.dateCreated
              let platform = base.platform 
              let link = base.link 
              let addedBy = base.added_by
              let escalated = base.escalated
              let solved = base.solved
              let uuid = base.uuid

              console.log(base.ongoing)

              const createOngoing = (name, dateCreated, platform, link, addedBy, escalated, solved, uuid, userId) => {
                console.log(name, dateCreated, platform, link, addedBy, escalated, solved, uuid, userId)
                swal
                .fire({
                  title: "You want to create a new ongoing issue?",
                  icon: "warning",
                  
                  showDenyButton: true,
                  confirmButtonText: "Yup",
                  
                  denyButtonText: "No no..."
                  
                })
                .then((result) => {
                  if (result.isConfirmed) {
                  
                    axios.post(apiurl+`ongoing_issues?key=${key}`, {
                      userId : userId,
                      user_name:name,
                      platform:platform,
                      link:link,
                      added_by:addedBy,
                      escalated:escalated,
                      solved:solved,
                      issue_id:uuid,
                      dateCreated: dateCreated
                    }).then(res => {
                      console.log(res.data.data)
                      if (res.data.data === "OK") {
                        swal.fire({
                          title: "Yay entry has been created",
                        });
                        setReload(true)
                      } else if (res.status === 202) {
                        swal.fire({
                          title: "Dang, issue already exists",
                        });
                      }
                    })
                  } 
                  else if (result.isDenied) {
                    
                    swal.fire({
                      title: "Nottinhappened.",
                    });                    

                  } 
                  
                });
              }

              return (
                <div>
             {base.solved === "true" ? '' : base.ongoing === 1 ? <button disabled className="disabledButton">Added</button> : <button className="Submit" onClick={() => createOngoing(name,dateCreated,platform,link,addedBy,escalated,solved,uuid, userId)}>Add +</button>}

                  
                 
                </div>
              )
            }
          },
        ],
      },
    ],
    []
  );
  return (
    <div className="TestList">
      <PaginatedTable columns={columns} data={data} reload={ReloadData} />

      {/* <CountIssues reload={reload}/> */}
    </div>
  );
}

export default TestList;

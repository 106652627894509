import axios from 'axios'
import React, {useState} from 'react'
import swal from 'sweetalert2';

function NewFeedback() {

    const key = process.env.REACT_APP_KEY;

const [uid, setUid] = useState("")
const [feedback, setFeedback] = useState("")
const [feedbackNature, setFeedbackNature] = useState("")

function handleChange(id){
 if(id.target.id === "user_id") {
     setUid(id.target.value)
 } else if (id.target.id === "feedback") {
     setFeedback(id.target.value)
}
else if (id.target.id === "feedbackNature") {
    setFeedbackNature(id.target.value)
}
}

function handleSubmit(e) {
    e.preventDefault();
    axios.post(process.env.REACT_APP_API_URL + `feedback/new?key=${key}`, 
    {
        user_id:uid,
        feedback:feedback.toString(),
        feedbackNature: feedbackNature
    }).then (response => {
        if(response.status === 200) {
            swal.fire("You are wonderful!", "Successfully submitted", "success");
               document.getElementById("feedbackForm").reset();
      

               } else if (response.status !== 200) {
                swal.fire("Uh-oh!", "Something is not right, we have dispatched a team of highly trained doggies. If you meet them, make sure to pet Nefi!", "error");
               }
        
        
    })
}
return (
<div className="InputContainer">
            <div className="InputContainerMiddle" id="inputContainer">
                
                <p className="Heading">Add new Feedback</p>
                <form  id="feedbackForm">
                    <div className="InputDiv">
                    <input className="Input" type="number" id="user_id" min="0" name="uid" autoComplete="off" onChange={(e) => handleChange(e)} placeholder="UID" required/>
                    </div>
                    <div className="InputDiv">
                    <textarea className="Input" type="text" name="agentVer"  id="feedback" autoComplete="off" onChange={(e) => handleChange(e)} placeholder="Feedback"/>
                    </div>
                    <div className="InputDiv">
                        <select onChange={(e) => handleChange(e)} id="feedbackNature" className="InputDrop">
                            <option value="" disabled selected>Feedback Nature</option>
                        <option value="positive">Positive</option>
                        <option value="negative">Negative</option>
                        <option value="mixed">Mixed</option>
                        </select>
                    </div>
              
                    <div className="InputDiv">
                <button onClick={(e) => handleSubmit(e)} className="Submit"> Send</button>
                </div>

                </form>

                </div>

            </div>
)
}

export default NewFeedback
import React, { useState } from "react";
import axios from "axios";
import swal from 'sweetalert2'

const apiurl = process.env.REACT_APP_API_URL;
const key = process.env.REACT_APP_KEY;

const UpdateIssues = () => {
  const [data, setData] = useState("");
  const [issue, setIssue] = useState("");
  const [show, setShow] = useState({
    date_created: "none",
    link: "none",
    issue_type: "none",
    platform: "none",
    currentData: "none",
  });
  const [newData, setNewData] = useState("");
  const [newLink, setNewLink] = useState("");
 
  const [field, setField] = useState("");
  
  const HandleChange = (e) => {
    setIssue(e.target.value);

  };

  const HandleNewData = (e) => {
    setNewData(e.target.value);
    setNewLink("")
    
    
  };

  const HandleNewLink = (e) => {
      
     setNewLink(e.target.value)
        
    
    
    
    
  };

  const GetData = (e) => {
    e.preventDefault();
    axios
      .post(apiurl + `search_issue?issue=${issue}&key=${key}`)
      .then((result) => {
        setData(result.data[0]);
        setShow({
          date_created: "none",
          link: "none",
          issue_type: "none",
          platform: "none",
          currentData: "",
        });
      });
  };

  const HandleOption = (e) => {
    setField(e.target.value);
    let option = e.target.value;
    if (option === "dateCreated") {
      setShow({
        date_created: "",
        link: "none",
        issue_type: "none",
        platform: "none",
        currentData: "",
      });
    } else if (option === "issueType") {
      setShow({
        date_created: "none",
        link: "none",
        issue_type: "",
        platform: "none",
        currentData: "",
      });
    } else if (option === "platform") {
      setShow({
        date_created: "none",
        link: "none",
        issue_type: "none",
        platform: "",
        currentData: "",
      });
    } else if (option === "link") {
      setShow({
        date_created: "none",
        link: "",
        issue_type: "none",
        platform: "none",
        currentData: "",
      });
    }
  };

  const HandleSubmit = (e) => {
    e.preventDefault();





    axios.patch(apiurl + `update_issue?key=${key}`, {
      uuid: issue,
      updateField: field,
      data: newData,
      newLink: newLink
    }).then(result => {
        console.log(result.status)
        if (result.status === 200) {
            swal.fire({
                showConfirmButton:false,
                title:"Issue Updated",
                timer:1000
                
              });
              setShow({date_created: "none",
              link: "none",
              issue_type: "none",
              platform: "none",
              currentData: "none",});
              setIssue("");
              setNewLink("")
              

        }
    } );
  };

  const style = {
    display: show.currentData,
  };

  return (
    <div className="InputContainerMiddle">
      <div className="Padding">
        <h1 className="Heading">Select issue</h1>
        <form>
          <div className="InputDiv">
            <input
              className="Input"
              type="number"
              onChange={(e) => HandleChange(e)}
              value={issue}
              type="number"
              min="0"
              name="issueId"
              autoComplete="off"
              placeholder="Issue ID"
              required
            />
            <button className="SendButton" onClick={(e) => GetData(e)}>
              Search{" "}
            </button>
          </div>
        </form>
        {show.currentData === "" ? (
          <div style={style}>
            <h1 className="Heading">Found issue:</h1>
            <div className="data-table">
              <p>User: {data.userName}</p>
              <p>Platform: {data.platform}</p>
              <p>Date Created: {data.dateCreated.slice(0, -5)}</p>
              <p>Note: {data.note}</p>
              <p>Issue Type: {data.issueType}</p>
            </div>
            
        <h1 className="Heading">
          Please select the field you would like to update
        </h1>
        <div className="InputDiv">
          <select
            title="What is the type of the issue?"
            className="InputDrop"
            name="module"
            onChange={(val) => HandleOption(val)}
            required
          >
            <option value="" disabled selected>
              Fields
            </option>
            <option value="dateCreated">Date Created</option>
            <option value="issueType">Issue Type</option>
            <option value="platform">Platform and Link</option>
            
          </select>
        </div>
        <div style={{ display: show.date_created === "none" ? "none" : "" }}>
          <input
            onChange={(e) => HandleNewData(e)}
            className="Input"
            style={{ marginTop: "20px" }}
            type="datetime-local"
            placeholder="New Date"
          />
        </div>

        <div style={{ display: show.platform === "none" ? "none" : "" }}>
          <select
            title="On what platform did the customer reported the issue?"
            onChange={(e) => HandleNewData(e)}
            className="InputDrop"
            style={{ marginTop: "20px" }}
            name="platform"
            required
          >
            <option value="" disabled selected>
              Choose New Platform
            </option>
            <option value="zenDesk">ZenDesk</option>
            <option value="Jira">Jira</option>
            <option value="Intercom">Intercom</option>
            <option value="Slack">Slack</option>
          </select>
          <div className="InputDiv">
            <input 
              onChange={(e) => HandleNewLink(e)}
              className="Input"
              style={{ marginTop: "20px" }}
              type="text"
              placeholder="New Link"
              value={newLink}
              
            />
          </div>

        </div>


        <div style={{ display: show.issue_type === "none" ? "none" : "" }}>
          <div className="InputDiv">
            <h1 className="Heading">Choose new issue type</h1>
            <select
              title="What is the type of the issue?"
              className="InputDrop"
              name="module"
              onChange={(e) => HandleNewData(e)}
              required
            >
              <option value="" disabled selected>
                Issue Type
              </option>
              <option value="Other">Other</option>
              <option value="License">License</option>
              <option value="Billing-Payment">Billing/Payment</option>
              <option value="Install Help">Install Help</option>
              <option value="General Question">General Question</option>
              <option value="Config Help">Config Help</option>
              <option value="IP Filter">IP Filter</option>
              <option value="WAF">WAF</option>
              <option value="Port Honeypot">PortHoneypot</option>
              <option value="Anti Flood">Anti Flood</option>
              <option value="DoS Detection">DoS Detection</option>
              <option value="Malware Detection">Malware Detection</option>
              <option value="Sense Log">Sense Log</option>
              <option value="Defense Robot">Defense Robot</option>
              <option value="Proxy Filter">Proxy Filter</option>
            </select>
          </div>
        </div>

        <button className="Submit" style={{ marginTop: "40px" }} onClick={(e) => HandleSubmit(e)}>Update Issue</button>
          </div>

          //IDEJÖNMINDENMÁS//
        ) : (
          ""
        )}

      </div>
    </div>
  );
};

export default UpdateIssues;

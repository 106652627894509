import axios from "axios";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
// import swal from "sweetalert2";


// import Legend from "../components/legend"
import FeedbacksTable from "./feedbacks-table";




function FeedbacksList() {

  const apiurl = process.env.REACT_APP_API_URL;

  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);
  
  const key = process.env.REACT_APP_KEY;

  const [text, setText]  = useState("")
  const [show, setShow] = useState(true)
  const [block,setBlock] = useState(true)

  const handleChange = () => {
        setShow(true)
        setBlock(true)
  }
  

  useEffect(() => {
    (async () => {
      console.log(apiurl);
    const result = await axios(apiurl + `feedback/getAll?key=${key}`);
      setData(result.data);
    })();
    setReload(false);
  }, [reload]);

  //Reload data in the table//

  const ReloadData = () => {
    setReload(true);
  };


  const columns = useMemo(
    () => [
      {
        Header: " ",

        columns: [
          {
            Header: "UID",
            accessor: "user_id",
            Cell: (userId) => (
              <a
                href={
                  "https://office.bitninja.io/index.php/user/view?id=" +
                  userId.value
                }
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                {userId.value}{" "}
              </a>
            ),
          },
          {
            Header:"Feedback",
            accessor:"feedback",
            Cell: (v) => {
              
   

              let ChangeBlock = () => {
              
                setBlock(!block)
                setShow(false)
              }


              let addText = (d) => {
                setText(d)
                setShow(false)
                setBlock()
              }
          
              return (
                <div className="Hover">
                  <h3 onClick={() => addText(v.value)}>{v.value.slice(0,40)+"..."}</h3>

                </div>
              )
            }
          },
          {Header: "Feedback ID",
        accessor:"feedback_id", 
      Cell: (v) => {

        const copyId = (uuid) => {
          navigator.clipboard.writeText(uuid)
        }
        return (
          <p className="Pointer" onClick={() => copyId(v.row.original.feedback_id)}>{v.value}</p>
        )
      }},
          {Header:"Feedback Nature",
        accessor:"feedback_nature",
        Cell: (v)=> {

          let clr = {
            color:"",
            size:""
          }
          if (v.value==="mixed"){
            clr.color = "grey"
            clr.size="15px"
          } else if (v.value==="positive"){
            clr.color = "green"
            clr.size="20px"
          } else if (v.value==="negative"){
            clr.color = "red"
            clr.size="35px"
          }
          
          return(
            <p style={{color:`${clr.color}`, fontSize:`${clr.size}`, textTransform:"capitalize"}}>{v.value}</p>
          )
     
        }
      },

          {
            Header: "Created On",
            accessor: "date_created",
            Cell: (v) => {
              moment.locale("hu");
              const addedDate = Date.parse(v.value);
              const parsedDate = moment(addedDate).format('YYYY-MM-DD')
              const nowDate = new Date()
              const today = nowDate.getFullYear()+'-'+(nowDate.getMonth()+1)+'-'+nowDate.getDate()
              var start = moment(today, "YYYY-MM-DD");
              var end = moment(parsedDate, "YYYY-MM-DD");
              
              //Difference in number of days
              
             

                let dayDiff = moment.duration(start.diff(end)).asDays()
                
            //Difference in number of days
            
              return <p>{parsedDate}</p>;
            },
          },

          
          // {
          //   Header: "Risk Level",
          //   Cell: (props) => {
          //     let level = props.row.original.risk_level
          //     let feedback_id = props.row.original.feedback_id
          //     let text = ''
          //     let [editable,setEditable] = useState(false)
          //     let [severityLevel,setSeverityLevel] = useState('')

          //     const handleChange = (e) => {
          //       let value = e.target.value
          //       setSeverityLevel(value)
          //     }
              
          //     const editNote = () => {
          //       setEditable(true)
          //     }

          //     const saveNote = () => {
              

          //       if (!severityLevel || level === severityLevel) {
          //         swal.fire({
          //           showConfirmButton:false,
          //           text:"You changed nothing",
          //           timer:1000
          //         })
          //       } else (
          //         axios.patch(apiurl + `feedbacks/?key=${key}`, {
          //           feedback_id: feedback_id,
          //           severity:severityLevel
          //       }).then(result => {
          //         console.log(result)
          //         if(result.status === 200){
          //           swal.fire({
          //             title:`Severity changed to Level ${severityLevel}`,
          //             icon:"success",
          //             showConfirmButton:false,
          //             timer:1500
          //           })
          //         } 
          //         setReload(true)
          //       }).catch(err => swal.fire({
          //         icon:"error",
          //         title:"Something went wrong, please try again!"
          //       }))
          //       )
          //       setEditable(false)
         
          //     }
          //     if(level > 0) {
                
          //       text = "Level "+level
          //     } else (text = "No risk")
          //     return(
          //       <>
                
          //         {editable ? (
          //           <>
          //           <select onChange={e => handleChange(e)}>
          //           <option default>
          //         Select level
          //         </option>
          //         <option value="1">
          //         Level 1 
          //         </option>
          //         <option value="2">
          //         Level 2
          //         </option>
          //         <option value="3">
          //         Level 3
          //         </option>
          //         <option value="4">
          //         Level 4
          //         </option>

                  
          //         </select>
          //           <button
          //           className="Edit"
          //             onClick={() => {
          //               saveNote();
          //             }}
          //           >
          //             <span>💾</span>
          //           </button>
          //           </>
          //         ) : ( 
          //           <>
                    
          //           { level === 1 ? <p style={{color:"#FF4545"}}>{text}</p> : null}
          //           { level === 2 ? <p style={{color:"#FB6944"}}>{text}</p> : null}
          //           { level === 3 ? <p style={{color:"#FF875A"}}>{text}</p> : null}
          //           { level === 4 ? <p style={{color:"#FFAA71"}}>{text}</p> : null}
          //           { !level ? <p>{text}</p> : null}
          //           <button className="Edit" onClick={() => editNote()}>
          //             <span>✏️</span>
          //           </button>
          //           </>
          //         )}
          //       </>
          //     )
          //   }
          // },


         

        ],
      },
    ],
    []
  );
  return (
    <div className="TestList"> 
    {/* <Legend
    
    title="Risk Levels"
    firstLevel="Risk Level 1 - Highest Priority"
    secondLevel = "Risk Level 2 - High Priority"
    thirdLevel="Risk Level 3 - Medium Priority"
    fourthLevel="Risk Level 4 - Low Priority"
    firstLevelDesc="User is in churn danger!"
    secondLevelDesc="Had some critical issue / complaint lately"
    thirdLevelDesc="Had a few issue which was not critical to their company"
    fourthLevelDesc="Contacted us with a minor issue (technical or config)"
    
    
    
    />  */}

    
      <FeedbacksTable  show={show} columns={columns} data={data} reload={ReloadData} />
     <div id="feedbackText" style={ block ? {display:"none" } : {display:"block", marginBottom:"20px"}} className="InputContainerMiddle">
     
     <h1>{text} </h1> 
     <div><button style={{backgroundColor:"transparent", border:"none", fontSize:"40px"}} onClick={() => handleChange()}>⬅️</button></div>
     </div>

      {/* <CountIssues reload={reload}/> */}
    </div>
  );
}

export default FeedbacksList;

import axios from 'axios';
import React, { useEffect, useState } from 'react';




function ListTopTwenty() {
    const [reload, setReload] = useState(false)
    const [baseData, setData] = useState()
    const [loading, setLoading] = useState(true)
    const [edit, setEdit] = useState(false)
    const secretKey = process.env.REACT_APP_KEY;





    useEffect(() => {

        (async () => {

            const result = await axios(`https://hook.eu1.make.com/42furc5e6a58yfxehor76cjluaq72tt2?Key=${secretKey}&type=all`);

            setData(result.data);
            setLoading(false);

        })();
        setReload(false);
    }, [reload]);




    return (
        <div className="center">

            {loading ? <div><h1 style={{ color: "white" }}>Loading....</h1></div> :


                <>

                    <h1 className="textColor">Current status of our TOP20 Customers</h1>

                    <div className="flexContainer">


                        {baseData.map((val, key) => {




                            let officeLink = "https://office.bitninja.io/index.php/user/view?id=" + val[0]


                            let risk = ''
                            let levels = 'none'
                            let chosenRisk = ''
                            let backGroundColor = ''
                            let sign = ''
                            let issueLink = 'https://metabase.bitninja.io/question/1860-user-issues-by-id?userId='
                            let feedbackLink = 'https://metabase.bitninja.io/question/1863-feedback-nature-by-user-top20?user_id='




                            if (val[3] === "1") {
                                // Low Risk
                                risk = <div id="risk-level" style={{ backgroundColor: "#FFAA71", color: "white", padding: "10px", margin: "none", fontWeight: "700", border: "3px solid black" }}> Low risk </div>
                                levels = "Low Risk"
                                sign = '👀'

                            } else if (val[3] === "2") {
                                risk = <div id="risk-level" style={{ backgroundColor: "#FF875A", color: "white", padding: "10px", margin: "none", fontWeight: "700", border: "3px solid black" }}> Medium risk </div>
                                levels = "Medium Risk"
                                sign = '⚠️'
                            } else if (val[3] === "3") {
                                risk = <div id="risk-level" style={{ backgroundColor: "#FF4545", color: "white", padding: "10px", margin: "none", fontWeight: "700", border: "3px solid black" }}> High risk </div>
                                levels = "High Risk"
                                sign = '🚨'
                            } else {
                                risk = <div id="risk-level" style={{ backgroundColor: "#90EE90", color: "white", padding: "10px", margin: "none", fontWeight: "700", border: "3px solid black" }}> No risk </div>
                                levels = "No Risk"
                                sign = '☑️'
                            }




                            function handleChange(d) {
                                chosenRisk = parseInt(d.value)
                            }

                            function saveRisk(d) {
                                console.log(chosenRisk, d.val.risk_level)

                                if (chosenRisk.length === 0) {
                                    setEdit(false)
                                } else {

                                    axios.patch(process.env.REACT_APP_API_URL + `risk_level?key=${secretKey}`, {
                                        risk_level: chosenRisk,
                                        id: d.val.id
                                    }).then(res => {
                                        if (res.status === 200) {
                                            setEdit(false)
                                            setReload(true)
                                        }
                                    })

                                }



                            }

                            function editRisk() {
                                setEdit(true)
                            }

                            const styleHighRisk = {
                                backgroundColor: "#FF4545",
                            }

                            const styleMediumRisk = {
                                backgroundColor: "#FF875A"
                            }
                            const styleLowRisk = {
                                backgroundColor: "#FFAA71"
                            }
                            const styleNoRisk = {
                                backgroundColor: "#90EE90"
                            }

                            function copyData(data) {
                                navigator.clipboard.writeText(data)
                            }


                            return (

                                <>
                                    <div id={key} className="itemContainer" style={val[3] === "0" ? styleNoRisk : val[3] === "1" ? styleLowRisk : val[3] === "2" ? styleMediumRisk : val[3] === "3" ? styleHighRisk : { backgroundColor: "blue" }}>
                                        <div className="card">
                                            <span>{sign}</span>
                                            <div className="textContainer">
                                                <h2 className="Pointer" onClick={() => copyData(val[1])}>{val[1]}</h2>
                                                <p>Current MRR : ${val[2]}</p>
                                                <p>UserID: <a href={officeLink}>{val[0]}</a></p>
                                                <div class="col col-4" data-label="Risk Status">{risk}</div>
                                                <div className="aHref">
                                                    <a href={issueLink + val[0]}>Issues of the User</a>
                                                </div>
                                                <div className="aHref">
                                                    <a href={feedbackLink + val[0]}>Feedback(s) of the User</a>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </>


                            )
                        })}



                        {/* </ul> */}
                    </div>
                </>




            }


        </div>
    )
}

export default ListTopTwenty
import axios from 'axios';
import React, { useContext,useState } from 'react';
import { useEffect } from 'react';
import { UserContext } from '../context/userContext';

export default function Alert(props) {
    const key = process.env.REACT_APP_KEY;
    const { email } = useContext(UserContext);
    let userEmail = email.split("@");
    let user =""
   
   
    
    let [data,setData] = useState("")
    let [link,setLink] = useState("")
    let [loading,setLoading] = useState(true)
    let [reload,setReload] = useState(false)
    let [show, setShow] = useState(false)

    useEffect(()=> {
        axios.get(process.env.REACT_APP_API_URL + `alert?key=${key}&user=${userEmail[0]}`).then(result => {setData(result.data); setLoading(false)}).catch(error => console.log(error));
        axios.get('https://hook.eu1.make.com/29hqai1j1a44pu4q73soo4utq1m2arpv').then(result => setLink(result.data))
        setReload(false)        
    },[reload])

    const Solve = () => {
        axios.post(process.env.REACT_APP_API_URL + `alert?key=${key}&user=${userEmail[0]}`)
        setReload(true)
    }

    if (loading = false ){
       if(data[0].alert.length > 0) {
           setShow(true)
       } else(console.log("No Data"))
    }
    
   
    if(email.includes("jacint.lazok") === true){
        user = "Jácint"
        
    } else if(email.includes("balazs.konczili")===true || email.includes("blaise")===true){
        user="Bazsi"
       
    } else if (email.includes("akos.szoke")===true){
        user="Ákos"
        
    }else if (email.includes("laci")===true){
        user="Taki"
       
        
    } 
    return(
        <>
     {loading  ? <h1>LOADING</h1>  : show === true && data[0].alert === 1  ? <div className="top white alertBox">
         <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"/>
        
        <span class="material-symbols-outlined">
warning
</span>  
<p><span style={{fontSize:"35px", textDecoration:"underline"}}>{user}</span> you have not done your weekly report yet, please check it out.</p>
<div><button onClick={() => Solve()}>I have solved it already</button> <a style={{marginLeft:"25px"}} href={link}>Link to report</a></div>

     </div> : "" }
     </>
    )
}

